<template>
	<layouts-basic :class="results.background">
		<div class="card border-0 mt-18">
			<div class="card-body text-center">
				<h3>{{ results.title }}</h3>
				<h5 class="mt-2" v-html="results.description"></h5>
				<div class="d-grid gap-2 mt-7">
					<router-link :to="{name: 'cart.checkout'}" class="btn btn-dark">Continuar</router-link>
				</div>
			</div>
		</div>
	</layouts-basic>
</template>

<script>
	import { ref, reactive, onBeforeMount, onBeforeUnmount } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		setup() {
			const { params: { gateway }, query } = useRoute();
			const { redirectBack } = composableRedirect();
			const timeout = ref(null);
			const results = reactive({
				background: null,
				title: null,
				description: null
			})

			const redirect = () => router.push({ name: 'cart.checkout' });

			onBeforeMount(() => {
				switch(gateway) {
					case 'bancard':
						switch(query.status) {
							case 'add_new_card_success':
								results.background = 'bg-primary';
								results.title = '¡Carga exitosa!';
								results.description = query.description ?? 'La carga de tu tarjeta fué exitosa, <span class="d-inline-block">ya puedes continuar comprando.</span>';
								break;
							case 'add_new_card_fail':
								results.background = 'bg-danger';
								results.title = 'Carga incorrecta';
								results.description = query.description ?? 'La carga de tu tarjeta fué exitosa, <span class="d-inline-block">ya puedes continuar comprando.</span>';
								break;
						}
						break;
				}

				timeout.value = setTimeout(redirect, 5000);
			});

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			if(!gateway) return redirectBack();

			return { results, redirect };
		}
	}
</script>